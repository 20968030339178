<template>
    <div style="min-width: 850px;">
        <!-- 设备类型 -->
        <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
            <div class="form-title" style="margin-bottom:0;">设备类型</div>
            <el-button type="primary" size="small" @click="addDevice">新增设备</el-button>
        </div>
        <!-- 循环设备Tag -->
        <div class="custom-attr" v-for="(dItem,dIndex) in equipments" :key="dIndex">
            <div style="display: flex;justify-content: space-between;">
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="设备名称">
                        <el-input v-model="dItem.equipmentName" size="small" style="width: 70%;" disabled placeholder="请选择设备"></el-input>
                        <el-button type="primary" size="small" @click="selectDevice(dItem)">选择设备</el-button>
                    </el-form-item>
                    <el-form-item label="点表名称">
                        <span v-if="resource=='add'">
                            <span v-if="dItem.triggerCondition.boolData">
                                <el-input v-model="dItem.triggerCondition.boolData.boolTag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                            </span>
                            <span v-else-if="dItem.triggerCondition.realData">
                                <el-input v-model="dItem.triggerCondition.realData.tag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                            </span>
                        </span>
                        <span v-if="resource=='edit'">
                            <span v-if="!dItem.triggerCondition.dataType&&dItem.abilityOrPointTable==1">
                                <el-input v-model="dItem.triggerCondition.boolData.boolTag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                            </span>
                            <span v-if="!dItem.triggerCondition.dataType&&dItem.abilityOrPointTable==0">
                                <el-input v-model="dItem.triggerCondition.realData.tag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                            </span>
                            <span v-if="!dItem.triggerCondition.dataType&&dItem.abilityOrPointTable!=0&&dItem.abilityOrPointTable!=1">
                                <el-input v-model="dItem.triggerCondition.realData.tag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                            </span>

                            <span v-if="dItem.triggerCondition.dataType=='Bool'">
                                <span v-if="dItem.triggerCondition.boolData.formulaType">
                                    <el-input v-model="dItem.triggerCondition.boolData.boolTag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                                </span>
                                <span v-else>
                                    <el-input v-model="dItem.triggerCondition.boolData.boolTag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                                </span>
                            </span>
                            <span v-if="dItem.triggerCondition.dataType=='Real'">
                                <span v-if="dItem.triggerCondition.realData.formulaType">
                                    <el-input v-model="dItem.triggerCondition.realData.tag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                                </span>
                                <span v-else>
                                    <el-input v-model="dItem.triggerCondition.realData.tag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                                </span>
                            </span>
                        </span>
                        <el-button type="primary" size="small" @click="selectPoint(dItem)" :disabled="!dItem.equipmentName">选择点表</el-button>
                    </el-form-item>
                    <el-form-item label="数据类型">
                        <el-select v-model="dItem.triggerCondition.dataType" size="small" :disabled="!dItem.parameterCn&&!dItem.pointName" style="width: 100%;margin-right:60px;" placeholder="请选择数据类型" @change="changeDataType">
                            <el-option
                                v-for="itemType in dataType"
                                :key="itemType"
                                :label="itemType"
                                :value="itemType"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运算类型" v-if="dItem.triggerCondition.dataType=='Bool'" style="margin-left: 5px;">
                        <el-select v-model="dItem.triggerCondition.boolData.formulaType" style="width: 100%;margin-right:60px;" size="small" placeholder="请选择运算类型" @change="changeDataType">
                            <el-option
                                v-for="itemType in boolFormulaType"
                                :key="itemType.code"
                                :label="itemType.name"
                                :value="itemType.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运算类型" v-if="dItem.triggerCondition.dataType=='Real'" style="margin-left: 5px;">
                        <el-select v-model="dItem.triggerCondition.realData.formulaType" style="width: 100%;margin-right:60px;" size="small" placeholder="请选择运算类型" @change="changeDataType">
                            <el-option
                                v-for="itemType in realFormulaType"
                                :key="itemType.code"
                                :label="itemType.name"
                                :value="itemType.code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div>
                    <div v-if="dIndex!=0" class="close-icon" @click="delDevice(dIndex)">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div v-if="dItem.triggerCondition.dataType=='Bool'">
                <div v-if="dItem.triggerCondition.boolData.formulaType">
                    <el-row :gutter="20">
                        <el-col style="width:79%;">
                            <el-form-item label="运算公式"></el-form-item>
                        </el-col>
                        <el-col style="width:21%;display:flex;justify-content:flex-end;">
                            <div v-if="dItem.triggerCondition.boolData.formulaType=='empty'||dItem.triggerCondition.boolData.formulaType=='!'"></div>
                            <div v-else>
                                <el-button v-show="dItem.triggerCondition.boolData.tagCustom.length>1" type="danger" size="mini" @click="delTag(dItem.triggerCondition.boolData.tagCustom)">删除Tag</el-button>
                                <el-button type="primary" size="mini" @click="addTag(dItem.triggerCondition.boolData.tagCustom)">新增Tag</el-button>
                            </div>
                        </el-col>
                    </el-row>
                    <!-- 运算符为无 以及 !-->
                    <div v-if="dItem.triggerCondition.boolData.formulaType=='empty'||dItem.triggerCondition.boolData.formulaType=='!'">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="Tag">
                                    <el-button v-if="!dItem.triggerCondition.boolData.boolTag" class="lube-btn" type="primary" size="small" @click.prevent="selectTag(dItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{dItem.triggerCondition.boolData.boolTag}}
                                        <span class="el-icon-error close-lube" @click="clearTag(dItem.triggerCondition.boolData)"></span>
                                    </span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="单位">
                                    <el-input v-model="dItem.triggerCondition.boolData.boolUnit" size="small" placeholder="请输入单位"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="描述">
                                    <el-input v-model="dItem.triggerCondition.boolData.boolDesc" size="small" placeholder="请输入描述"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-else>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="Tag">
                                    <el-button v-if="!dItem.triggerCondition.boolData.boolTag1" class="lube-btn" type="primary" size="small" @click.prevent="selectTag(dItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{dItem.triggerCondition.boolData.boolTag1}}
                                        <span class="el-icon-error close-lube" @click="clearTag(dItem.triggerCondition.boolData)"></span>
                                    </span>
                                </el-form-item>
                            </el-col>
                            <div v-for="(tagItem, tagIndex) in dItem.triggerCondition.boolData.tagCustom" :key="tagIndex">
                                <el-col :span="1">
                                    <div style="font-size:14px;width:30px;line-height:40px;text-align:center;background:#efefef;border-radius:5px;margin-left:10px;">
                                        <span v-show="dItem.triggerCondition.boolData.formulaType=='&'">&</span>
                                        <span v-show="dItem.triggerCondition.boolData.formulaType=='||'">||</span>
                                        <span v-show="dItem.triggerCondition.boolData.formulaType=='!'">!</span>
                                    </div>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="Tag" label-width="60px">
                                        <el-button v-if="!tagItem.name" class="lube-btn" type="primary" size="small" @click.prevent="selectTagItem(tagItem,dItem)">选择Tag</el-button>
                                        <span v-else class="close-lube-btn">
                                            {{tagItem.name}}
                                            <span class="el-icon-error close-lube" @click="clearTagItem(tagItem)"></span>
                                        </span>
                                    </el-form-item>
                                </el-col>
                                <!-- 侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="tagItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                        <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                                            <el-table-column label="勾选" width="70">
                                                <template slot-scope="scope">
                                                    <el-radio-group v-model="tagItem.tagRadio" @change="checkTagChange(scope.row,dItem)">
                                                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                                                    </el-radio-group>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                            <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                            <el-table-column prop="pointName" label="点名"></el-table-column>
                                            <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                            <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                            <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                        </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                        <el-button size="small" type="primary" @click="tagItem.tagDrawer=false">取消</el-button>
                                        <el-button
                                            size="small"
                                            type="primary"
                                            @click="tagItemDrawerOK(tagItem, dItem)"
                                            class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="单位">
                                    <el-input v-model="dItem.triggerCondition.boolData.boolUnit" size="small" placeholder="请输入单位"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="描述">
                                    <el-input v-model="dItem.triggerCondition.boolData.boolDesc" size="small" placeholder="请输入描述"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <!-- 触发条件 -->
                    <div class="tag-explain">
                        <span class="tag-explain-title">触发条件</span>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="10">
                            <el-form-item label="点表值">
                                <el-input v-model="dItem.triggerCondition.boolData.tagValue" size="small" placeholder="请输入点表值"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="点表值含义">
                                <el-input v-model="dItem.triggerCondition.boolData.tagValueDescribe" size="small" placeholder="请输入含义"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div v-if="dItem.triggerCondition.dataType=='Real'">
                <div v-if="dItem.triggerCondition.realData.formulaType">
                    <el-form-item label="运算公式">
                        <div v-show="dItem.triggerCondition.realData.formulaType=='empty'">无</div>
                        <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='gt'">
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">&gt;</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.threshold" size="small" placeholder="请输入标准阈值" @input="changeInput"></el-input>
                            </div>
                        </div>

                        <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='ge'">
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">>=</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.threshold" size="small" placeholder="请输入标准阈值" @input="changeInput"></el-input>
                            </div>
                        </div>
                        <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='lt'">
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">&lt;</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.threshold" size="small" placeholder="请输入标准阈值" @input="changeInput"></el-input>
                            </div>
                        </div>
                        <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='le'">
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">&lt;=</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.threshold" size="small" placeholder="请输入标准阈值" @input="changeInput"></el-input>
                            </div>
                        </div>
                        <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='mid'">
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.minThreshold" size="small" placeholder="请输入最小阈值" @input="changeInput"></el-input>
                            </div> 
                            <div class="f-symbol">&lt;</div>
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">&lt;</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.maxThreshold" size="small" placeholder="请输入最大阈值" @input="changeInput"></el-input>
                            </div>
                        </div>
                        <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='side'">
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">&gt;</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.maxThreshold" size="small" placeholder="请输入最大阈值" @input="changeInput"></el-input>
                            </div>
                            <div class="f-symbol">||</div>
                            <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                            <div class="f-symbol">&lt;</div>
                            <div>
                                <el-input v-model="dItem.triggerCondition.realData.minThreshold" size="small" placeholder="请输入最小阈值" @input="changeInput"></el-input>
                            </div>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <!-- 选择设备类型 -->
            <el-drawer
                title=""
                :with-header="false"
                :visible.sync="dItem.drawer"
                direction="rtl"
                size="1000px">
                <div class="drawer-title">选择设备类型</div>
                <div class="drawer-content">
                    <div class="table-tree">
                        <div class="tree">
                            <div class="tree-title">
                                <div>
                                    <i class="el-icon-share" style="margin-right:10px;"></i>设备类型
                                </div>
                            </div>
                            <div class="tree-park" v-for="(item,index) in supplierData" :key="index">
                                <div class="park-title" :class="current==item.equipmentBrandCode?'bright':''" @click.prevent="brandToggle(item.equipmentBrandCode)">
                                <span class="class-icon iconfont icon-xiangmu" :style="current==item.equipmentBrandCode?'color:#FFF;':'color:#3498db;'"></span>
                                <span>{{item.equipmentBrand}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="table">
                            <el-table :data="tableData" style="width: 99%" height="700" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
                                <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="dItem.configRadio" @change="checkConfig(scope.row,dItem)">
                                            <el-radio :label="scope.row.eid">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="waterworks" label="水厂">
                                    <template slot-scope="scope">
                                    {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="position" label="泵房编号">
                                    <template slot-scope="scope">
                                    {{ scope.row.position?scope.row.position:'-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="equipmentName" label="设备名称">
                                    <template slot-scope="scope">
                                    {{scope.row.equipmentName?scope.row.equipmentName:'-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="eid" label="设备号"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="dItem.drawer=false">取消</el-button>
                    <el-button size="small" type="primary" @click="drawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
            <!-- 选择点表 -->
            <el-drawer
                title=""
                :with-header="false"
                :visible.sync="dItem.tagDrawer"
                direction="rtl"
                size="1000px">
                <div class="drawer-title">选择点表</div>
                <div class="drawer-content table-tree">
                    <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                        <el-table-column label="勾选" width="70">
                            <template slot-scope="scope">
                            <el-radio-group v-model="dItem.tagRadio" @change="checkTagChange(scope.row,dItem)">
                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                            </el-radio-group>
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                        <el-table-column prop="pointName" label="点名"></el-table-column>
                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                    </el-table>
                </div>
                <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="dItem.tagDrawer=false">取消</el-button>
                    <el-button size="small" type="primary" @click="deviceDrawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            supplierData: [],
            tableData: [],
            treeLoading: false,
            tableLoading: false,
            abilityLoading: false,
            current: null,
            selectCache: {},
            selectConfigObj: {},
            dataType: ['Bool','Real'],
            boolFormulaType: [
                { code: 'empty', name: '无' },
                { code: '&', name: '&' },
                { code: '||', name: '||' },
                { code: '!', name: '!' },
            ],
            realFormulaType: [
                { code: 'empty', name: '无' },
                { code: 'gt', name: '>' },
                { code: 'ge', name: '>=' },
                { code: 'lt', name: '<' },
                { code: 'le', name: '<=' },
                { code: 'mid', name: '<<(区间内运算)' },
                { code: 'side', name: '><(区间外运算)' },
            ],
            equipments: this.equipmentss,
            gateCurrent: null,
            devCurrent: null,
            tagCurrent: null,

            gatewayNodes: [],
            tagTableData: []
        }
    },
    props: {
        equipmentss: {
            type: Array
        },
        resource: {
            type: String
        }
    },
    methods: {
        changeDataType(e) {
            this.$forceUpdate()
        },
        changeInput(e) {
            this.$forceUpdate()
        },
        // 新增设备
        addDevice() {
            this.equipments.push({
                drawer: false,
                configRadio: '',
                tagDrawer: false,
                tagRadio: '',
                abilityOrPointTable: null,
                parameterEn: null,
                parameterCn: null,
                pointName: null,
                describe: null,

                gatewayEid:null,
                eid:null,
                equipmentBrandCode: null,
                equipmentName: null,
                triggerCondition: {
                    dataType:null,
                    boolData: {
                        formulaType: '',
                        tag: '',
                        tagValue: '',
                        tagValueDescribe:'',
                        boolTag: '',
                        boolTagEn: '',
                        boolUnit: '',
                        boolDesc: '',
                        boolTag1: '',
                        boolTag1En: '',
                        tagCustom: [{
                            tagDrawer: false,
                            tagRadio: '',
                            name: ''
                        },
                        {
                            tagDrawer: false,
                            tagRadio: '',
                            name: ''
                        }]
                    },
                    realData:{
                        tag:null,
                        formulaType:null,
                        threshold:null,
                        minThreshold:null,
                        maxThreshold:null
                    }
                }
            })
            this.$forceUpdate()
        },
        // 删除设备
        delDevice(index) {
            this.equipments.splice(index,1)
        },
        // 选择设备项目
        selectDevice(dItem) {
            dItem.drawer = true
            if(dItem.equipmentBrandCode) {
                this.current = dItem.equipmentBrandCode
                this.loadListData()
            }
            this.selectCache.gatewayEid = dItem.gatewayEid
            this.selectCache.eid = dItem.eid
            this.selectCache.equipmentName = dItem.equipmentName
            this.selectCache.equipmentBrandCode = dItem.equipmentBrandCode
            this.selectCache.abilityOrPointTable = dItem.abilityOrPointTable
        },
        // 勾选设备项目
        checkConfig(row,dItem) {
            this.selectCache = {}
            dItem.configRadio = row.eid
            this.selectCache = row
        },
        // 确认选择设备项目
        drawerOK(dItem) {
            if(dItem.equipmentBrandCode!=this.selectCache.equipmentBrandCode) {
                dItem.gatewayEid = null
                dItem.eid = null
                dItem.equipmentName = null
                dItem.equipmentBrandCode = null
                dItem.abilityOrPointTable = null
                dItem.triggerCondition = {
                    dataType:null,
                    boolData: {
                        formulaType: '',
                        tag: '',
                        tagValue: '',
                        tagValueDescribe:'',
                        boolTag: '',
                        boolTagEn: '',
                        boolUnit: '',
                        boolDesc: '',
                        boolTag1: '',
                        boolTag1En: '',
                        tagCustom: [{
                        tagDrawer: false,
                        tagRadio: '',
                        name: ''
                        },
                        {
                        tagDrawer: false,
                        tagRadio: '',
                        name: ''
                        }],
                    },
                    realData:{
                        tag:null,
                        formulaType:null,
                        threshold:null,
                        minThreshold:null,
                        maxThreshold:null
                    }
                }
            }
            dItem.gatewayEid = this.selectCache.gatewayEid
            dItem.eid = this.selectCache.eid
            dItem.equipmentName = this.selectCache.equipmentName
            dItem.equipmentBrandCode = this.selectCache.equipmentBrandCode
            this.selectConfigObj = this.selectCache
            dItem.abilityOrPointTable = this.selectConfigObj.abilityOrPointTable
            dItem.drawer = false
        },
        // 选择点表
        selectPoint(dItem) {
            dItem.tagDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 选择tag赋值点表数据
        checkTagChange(row,dItem) {
            this.tagSelectObj = row
        },
        // 确定选择设备Tag
        deviceDrawerOK(dItem) {
            dItem.pointName = this.tagSelectObj.pointName
            dItem.describe = this.tagSelectObj.describe
            dItem.triggerCondition.realData.tag = this.tagSelectObj.pointName

            dItem.triggerCondition.boolData.boolTag = this.tagSelectObj.pointName
            dItem.triggerCondition.boolData.boolTag1 = this.tagSelectObj.pointName
            dItem.triggerCondition.boolData.boolDesc = this.tagSelectObj.describe
            this.$forceUpdate()
            dItem.tagDrawer = false
        },
        // 新增Tag
        addTag(arr) {
            arr.push({
                tagDrawer: false,
                tagRadio: '',
                name: ''
            })
        },
        // 删除Tag
        delTag(arr) {
            arr.pop()
        },
        // 选择Tag
        selectTag(dItem) {
            dItem.tagDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 清除Tag
        clearTag(dItem) {
            dItem.boolTag = ''
            dItem.boolTagEn = ''
            dItem.boolTag1 = ''
            dItem.boolTag1En = ''
            dItem.boolDesc = ''
            this.$forceUpdate()
        },
        // 运算符循环Tag选择
        selectTagItem(tagItem,dItem) {
            tagItem.tagDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 运算符循环Tag清除
        clearTagItem(tagItem) {
            tagItem.name = ''
            this.$forceUpdate()
        },
        // 运算符循环Tag确认
        tagItemDrawerOK(tagItem, dItem) {
            dItem.boolDesc = ''
            tagItem.name = this.tagSelectObj.pointName
            tagItem.tagDrawer = false
        },
        // 获取设备
        loadListData() {
            this.tableLoading = true
            this.$ajax.post('equipmentByEcode',{
                pageNum: 0,
                pageSize: 0,
                equipmentBrandCode: this.current,
            }).then(res => {
                res.data.forEach(v=>{
                    v.check = false
                })
                this.tableData = res.data
                this.tableLoading = false
            }).catch(err=>{
                this.tableLoading = false
            })
        },
        // 切换设备项目
        brandToggle(equipmentBrandCode) {
            this.current = equipmentBrandCode
            this.equipmentBrandCode = equipmentBrandCode
            sessionStorage.setItem('equipmentBrandCode',equipmentBrandCode)
            this.loadListData()
        },
        // 获取品牌
        loadBrand() {
            this.treeLoading = true
            this.$ajax.post('equipmentGroupQuery',{
                deviceTypeCodes: ['GatewaySub']
            }).then(res => {
                if(res.data.length==0) {
                    this.treeLoading = false
                }
                this.supplierData = res.data
                if(sessionStorage.getItem('equipmentBrandCode')){
                    this.current = sessionStorage.getItem('equipmentBrandCode')
                    this.equipmentBrandCode = sessionStorage.getItem('equipmentBrandCode')
                    this.loadListData()
                }else{
                    this.current = this.supplierData[0].equipmentBrandCode
                    this.equipmentBrandCode = this.supplierData[0].equipmentBrandCode
                    this.loadListData()
                }
                this.treeLoading = false
            }).catch(err=>{
                this.treeLoading = false
            })
        },
        // 获取Tag列表
        loadTagsList(eid) {
            this.abilityLoading = true
            this.$ajax.post("iotTagsQuery", {}, { 
                eid
            }).then(res=>{
                this.tagTableData = res.data
                this.abilityLoading = false
            }).catch(err=>{
                this.abilityLoading = false
            })
        }
    },
    mounted() {
        if(this.resource=='add') {
            sessionStorage.removeItem('equipmentBrandCode')
            this.loadBrand()
        }
        if(this.resource=='edit') {
            sessionStorage.setItem('equipmentBrandCode',this.current) 
            // this.selectConfigObj.equipmentBrandCode = this.equipments.eid
            // this.configRadio = this.equipments.eid
            this.loadBrand()
        }
        this.$forceUpdate()
    }
}
</script>
<style lang="less" scoped>
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    min-width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
/deep/.el-drawer__body {
    height: 100%;
    margin-bottom: 50px;
}
.drawer-content {
    margin: 10px 0;
    height: 86%;
    overflow-y: auto;
}
.tree-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
  border-radius: 5px;
}
.custom-attr {
  border: 1px solid #ebeef5;
//   box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  .close-icon {
    width: 25px;
    background-color: #ff7979;
    color: #FFF;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease;
    margin-top: 8px;
    &:hover {
        background-color: rgba(255, 121, 121, .7);
    }
  }
}
.el-select {
    width: 100%;
}
.formula-type {
    display: flex;
    align-items: center;
}
.formula-left {
    background-color: #ecf5ff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    color: #409eff;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 14px;
}
.f-symbol {
    font-size: 14px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 5px;
    width: 30px;
    line-height: 30px;
    margin: 0 20px;
    color: #333;
}
.tag-explain {
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:20px 0px;
}
.tag-explain-title {
  font-size:14px;
  margin-left:40px;
  font-weight:bold;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  color: #409EFF;
  line-height: 32px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  .close-lube {
    cursor: pointer;
  }
}
</style>